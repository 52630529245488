var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "field__upload-placeholder",
      class: { "field__upload-placeholder--square": _vm.square },
    },
    [
      !_vm.loading
        ? _c("span", {
            staticClass: "field__upload-placeholder-icon",
            domProps: { innerHTML: _vm._s(_vm.svg.addImage) },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c("span", { staticClass: "field__upload-placeholder-caption" }, [
            _vm._v("Click here to upload"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("CircularSpinner", {
            attrs: { inverse: false, dark: _vm.dark, stroke: "4" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }