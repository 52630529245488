import { render, staticRenderFns } from "./image-upload-field-placeholder.vue?vue&type=template&id=737fd27c&"
import script from "./image-upload-field-placeholder.vue?vue&type=script&lang=js&"
export * from "./image-upload-field-placeholder.vue?vue&type=script&lang=js&"
import style0 from "./image-upload-field-placeholder.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/Frontend-old/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('737fd27c')) {
      api.createRecord('737fd27c', component.options)
    } else {
      api.reload('737fd27c', component.options)
    }
    module.hot.accept("./image-upload-field-placeholder.vue?vue&type=template&id=737fd27c&", function () {
      api.rerender('737fd27c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/pages/components/image-upload-field-placeholder.vue"
export default component.exports