var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "field",
      class: {
        "field--error": _vm.errors.length && _vm.errors[0].type === "error",
        "field--thin": !_vm.caption && !_vm.tall,
        "field--disabled": _vm.disabled,
      },
    },
    [
      _c("label", { staticClass: "field__label", attrs: { for: _vm._id } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "field__upload-label" },
        [
          _c("input", {
            ref: "input",
            staticClass: "field__upload-input",
            attrs: {
              type: "file",
              id: _vm._id,
              name: _vm.name,
              disabled: _vm.disabled,
            },
            on: {
              change: function ($event) {
                return _vm.change()
              },
            },
          }),
          _vm._v(" "),
          _vm._t("default", [
            _c("ImageUploadFieldPlaceholder", {
              attrs: { loading: _vm.loading },
            }),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm._caption
        ? _c("span", { staticClass: "field__caption" }, [
            _vm._v("\n    " + _vm._s(_vm.captionText) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }