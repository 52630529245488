<template>
  <div
    class="field"
    :class="{
      'field--error': errors.length && errors[0].type === 'error',
      'field--thin': !caption && !tall,
      'field--disabled': disabled,
    }"
  >
    <label class="field__label" :for="_id">
      {{ label }}
    </label>
    <label class="field__upload-label">
      <input
        class="field__upload-input"
        type="file"
        :id="_id"
        :name="name"
        @change="change()"
        ref="input"
        :disabled="disabled"
      />
      <slot>
        <ImageUploadFieldPlaceholder :loading="loading" />
      </slot>
    </label>

    <!--<span class="field__icon"
          :class="{
            'field__icon--small': icon.iconSmall,
            'field__icon--fixed': icon.iconFixed,
            'field__icon--fixed-toggled': toggled
          }"
          :title="icon.iconTitle"
          v-html="icon.iconSVG"
          @click="iconClick()"></span>-->
    <span v-if="_caption" class="field__caption">
      {{ captionText }}
    </span>
  </div>
</template>

<script>
import close from '../../img/baseline-close-24px.svg';
import error from '../../img/baseline-error-24px.svg';
import eye from '../../img/eye-icon.svg';

import compact from 'lodash/compact';
import { v4 as uuidv4 } from 'uuid';

import ImageUploadFieldPlaceholder from './image-upload-field-placeholder.vue';

export default {
  name: 'ImageUploadField',
  components: { ImageUploadFieldPlaceholder },
  props: ['id', 'type', 'label', 'name', 'caption', 'validation', 'tall', 'toggled', 'disabled', 'loading'],
  data() {
    return {
      svg: {},

      icons: {
        clear: {
          iconSVG: close,
          iconTitle: 'Clear',
          iconSmall: true,
        },
        toggleVisibility: {
          iconSVG: eye,
          iconTitle: 'Toggle visibility',
          iconFixed: true,
        },
      },
      step: 1,
      focus: false,
      validated: false,
      errors: [],
    };
  },
  computed: {
    _id() {
      return this.id || (this.name || '') + '-' + uuidv4();
    },
    _caption() {
      return (this.caption || this.errors.length) && !!this.captionText;
    },
    captionText() {
      return this.errors.length ? this.errors[0].message : this.caption;
    },
    icon() {
      if (this.type === 'password') return this.icons.toggleVisibility;

      if (!this.errors.length) return this.icons.clear;

      return this.errors[0];
    },
  },
  methods: {
    change() {
      this.validated = false;
      this.validate().catch(() => {}); // Ignore errors

      if (this.$refs.input) this.$emit('change', this.$refs.input.files);
    },
    clear() {
      // This is more tricky than one thinks, especially cross-browser (IE11): https://stackoverflow.com/questions/20549241/how-to-reset-input-type-file
      this.$refs.input.type = 'text';
      this.$refs.input.type = 'file';
    },
    addError(error) {
      this.errors.push(this.errorFormatter(error));
    },
    errorFormatter(err) {
      if (!err.message) err = { message: err };

      if (!err.type) err.type = 'error';
      if (!err.iconSVG) err.iconSVG = error;
      if (!err.iconTitle) err.iconTitle = 'Error!';

      return err;
    },
    validate() {
      return new Promise((resolve, reject) => {
        const done = (result) => {
          this.validated = true;

          return resolve(result);
        };

        if (!this.validation) return done(true);

        Promise.all(
          (Array.isArray(this.validation) ? this.validation : [this.validation]).map((v) => {
            let res = v(this.value);

            // Async validation
            if (res && res.then) return res;

            // Sync validation
            return Promise.resolve(res);
          })
        )
          .then((results) => {
            this.errors = compact(results).map(this.errorFormatter);

            done(!this.errors.length);
          })
          .catch(() => {
            // Skip client side validation if unknown errors happen
            resolve(true);
          });
      });
    },
    validates() {
      if (!this.validated) return this.validate();

      return Promise.resolve(!this.errors.length);
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// See ~common/styles/field.scss
</style>
