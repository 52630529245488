<template>
  <span class="field__upload-placeholder" :class="{ 'field__upload-placeholder--square': square }">
    <span class="field__upload-placeholder-icon" v-html="svg.addImage" v-if="!loading"></span>
    <span class="field__upload-placeholder-caption" v-if="!loading">Click here to upload</span>
    <CircularSpinner :inverse="false" v-if="loading" :dark="dark" stroke="4" />
  </span>
</template>

<script>
import addImage from '../../img/gallery-add.svg';
import CircularSpinner from './circular-spinner.vue';

export default {
  name: 'ImageUploadFieldPlaceholder',
  props: ['loading', 'dark', 'square'],
  components: { CircularSpinner },
  data() {
    return {
      svg: {
        addImage: addImage,
      },
    };
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// See ~common/styles/field.scss
</style>
